import styled from 'styled-components';
import { color, media } from 'ui/theme';

export const TableWrapper = styled.table`
  width: 100%;
  max-width: 100%;
  border-collapse: separate;
`;

export const HeadRow = styled.tr`
  background-color: ${color.backgroundBlack};
  height: 66px;

  th:first-child {
    border-top-left-radius: 39px;
    border-bottom-left-radius: 39px;
  }
  th:last-child {
    border-top-right-radius: 39px;
    border-bottom-right-radius: 39px;
  }

  ${media.smallComputer`
    display: none;
  `}
`;

export const HeadText = styled.th`
  text-align: left;
  padding: 14px 0;
  font-family: 'RobotoRegular', sans-serif;
  box-shadow: 0 10px 20px ${color.shadowBlackTransparent};

  [data-wrap='cell'] {
    display: flex;
    align-items: center;
    height: 39px;
    padding: 0 8px;

    border-left: 2px solid ${color.primary};

    span {
      margin-left: 4px;
      margin-top: 1px;
      color: ${color.grayLight};
      font-size: 14px;
      font-family: 'RobotoRegular', sans-serif;
    }
  }
  &:first-child {
    padding-left: 24px;

    [data-wrap='cell'] {
      border: none;
    }
  }
`;

export const BodyRow = styled.tr`
  box-shadow: 0 10px 20px ${color.shadowBlackTransparent};
  border-radius: 39px;
  transition: all 0.25s ease;

  &:first-child td {
    padding-top: 20px;
  }
  td:first-child [data-wrap='cell'] {
    padding-left: 24px;
    border-top-left-radius: 39px;
    border-bottom-left-radius: 39px;
  }
  td:last-child [data-wrap='cell'] {
    padding-right: 24px;
    border-top-right-radius: 39px;
    border-bottom-right-radius: 39px;
  }
  &.hover:hover {
    box-shadow: 0 10px 20px ${color.shadowPrimary};
  }
  td {
    padding-top: 12px;

    [data-wrap='cell'] {
      height: 48px;
      display: flex;
      align-items: center;

      padding: 8px 0;
      width: 100%;
      color: ${color.grayDark};
      background-color: ${color.white};
      font-family: 'RobotoMedium', sans-serif;

      a {
        color: ${color.primary};
      }
      .blue {
        padding: 3px 12px;
        font-size: 14px;
        color: ${color.white};
        background-color: ${color.primary};
        border-radius: 22px;
      }
      &.green > div {
        padding: 3px 12px;
        font-size: 14px;
        color: ${color.white};
        background-color: ${color.green};
        border-radius: 22px;
      }
      &.dark-blue > div {
        padding: 3px 12px;
        font-size: 14px;
        color: ${color.white};
        background-color: ${color.primary};
        border-radius: 22px;
      }
      &.dark-gray > div {
        padding: 3px 12px;
        font-size: 14px;
        color: ${color.white};
        background-color: ${color.grayDark};
        border-radius: 22px;
      }
      &.gray > div {
        padding: 3px 12px;
        font-size: 14px;
        color: ${color.black};
        background-color: ${color.gray2};
        border-radius: 22px;
      }
      &.blue > div {
        padding: 3px 12px;
        font-size: 14px;
        color: ${color.white};
        background-color: ${color.blueLight};
        border-radius: 22px;
      }
      &.blue-text {
        color: ${color.primary};
      }
      &.blue-light-text {
        color: ${color.blueLight};
      }
      &.green-text {
        color: ${color.green};
      }
      &.orange-text {
        color: ${color.orange};
      }
      &.red-text {
        color: ${color.red};
      }
    }
  }
  ${media.smallComputer`
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    max-width: 100%;
    box-shadow: none;

    td {
      [data-wrap='cell'] {
        position: relative;
        margin: 0 auto;
        padding: 8px 20px 8px 80px !important;
        display: flex;
        justify-content: flex-end;

        border-radius: 39px;
        box-shadow: 0 10px 20px ${color.shadowBlackTransparent};
        font-family: 'RobotoCondensedBold', sans-serif;

        > div {
          overflow: hidden;
          text-overflow: ellipsis;
        }
        &::before {
          content: attr(data-label);
          position: absolute;
          top: 50%;
          left: 24px;
          font-size: 15px;
          text-transform: uppercase;

          transform: translateY(-50%);
          font-family: 'RobotoCondensedRegular', sans-serif;
          color: ${color.grayDark}
        }
      }
    }
  `}
  ${media.mobile`
    td {
      [data-wrap='cell'] {
        font-size: 14px;
        max-width: calc(100vw - 30px);

        > a {
          overflow: hidden;
          text-overflow: ellipsis;
          direction: rtl;
          text-align: left;
        }
        &::before {
          font-size: 14px;
        }
      }
    }
  `}
`;
